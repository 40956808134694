/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-arrow--slider': {
    width: 16,
    height: 14,
    viewBox: '0 0 16 14',
    data: '<path pid="0" clip-rule="evenodd" d="M8.293.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L12.586 8H1a1 1 0 110-2h11.586L8.293 1.707a1 1 0 010-1.414z"/>'
  }
})


export default {
  name: 'AppLayout',
  data() {
    return {
      footer: null,
    }
  },
  async mounted() {
    try {
      const url = '/ucas/v1/get-template/4.9.4'
      const response = await this.$axios.$get(url)
      this.footer = response.parts.footer
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  },
}

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-chevron--long': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.77 20.832a1 1 0 01-1.415 0L6.293 16.77a1 1 0 010-1.415l4.062-4.062a1 1 0 011.415 1.414l-2.356 2.355h15.867a1 1 0 010 2H9.414l2.356 2.356a1 1 0 010 1.414z" _fill="#1F2834"/>'
  }
})

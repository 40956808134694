/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-download': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M3 2a1 1 0 00-1 1v14a1 1 0 001 1h10a1 1 0 001-1V7.414L8.586 2H3zM0 3a3 3 0 013-3h5.586A2 2 0 0110 .586L15.414 6A2 2 0 0116 7.414V17a3 3 0 01-3 3H3a3 3 0 01-3-3V3zm8 4a1 1 0 011 1v3.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 111.414-1.414L7 11.586V8a1 1 0 011-1z"/>'
  }
})

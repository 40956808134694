/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-twitter': {
    width: 16,
    height: 13,
    viewBox: '0 0 16 13',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.372 3.661c0-.142 0-.283-.01-.424A6.678 6.678 0 0016 1.537c-.6.266-1.235.44-1.885.517A3.295 3.295 0 0015.558.238a6.579 6.579 0 01-2.085.797A3.285 3.285 0 007.88 4.029 9.32 9.32 0 011.114.6 3.286 3.286 0 002.13 4.982 3.258 3.258 0 01.64 4.57v.041a3.284 3.284 0 002.634 3.219c-.484.132-.99.15-1.483.056a3.286 3.286 0 003.067 2.28A6.586 6.586 0 010 11.527 9.292 9.292 0 005.032 13c6.037.003 9.34-5 9.34-9.339z" _fill="#000"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-burger-menu': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" d="M2 2h16M2 10h16" _stroke="#1F2834" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-email': {
    width: 20,
    height: 17,
    viewBox: '0 0 20 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 3.514A3.004 3.004 0 013 .507h14c1.66 0 3 1.35 3 3.007V13.551a3.004 3.004 0 01-3 3.007H3c-1.66 0-3-1.35-3-3.007v-9.02-1.017zM2 6.39v7.161c0 .56.451 1.008 1 1.008h14c.549 0 1-.448 1-1.008v-7.16l-6.334 4.238a2.994 2.994 0 01-3.331 0L2 6.39zm16-2.406l-7.447 4.983a.994.994 0 01-1.106 0L2 3.984v-.47c0-.56.451-1.007 1-1.007h14c.549 0 1 .447 1 1.007v.47z" _fill="#000"/>'
  }
})


import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonPrimary',
  props: {
    title: {
      required: true,
      type: String,
    },
    url: {
      required: true,
      type: String,
    },
    theme: {
      required: true,
      type: String,
    },
    icon: {
      required: false,
      type: String,
      default: null,
    },
    overide: {
      required: false,
      type: String,
      default: 'link',
    },
  },
  computed: {
    style() {
      const iconModifier = this.icon !== null ? 'py-[6px]' : 'py-[10px]'
      const defaultStyling = `flex justify-center items-center text-base text-white transition-colors cursor-pointer font-bold rounded-full block px-5 ${iconModifier}`
      switch (this.theme) {
        case 'secondary':
          return `${defaultStyling} bg-transparent border-white border-2 hover:bg-white hover:text-black`
        case 'footer':
          return `${defaultStyling} bg-black text-white border-black border-2 hover:bg-transparent hover:text-black inline-flex`
        case 'nav':
          return `group mb-5 flex h-10 w-10 items-center justify-center rounded-full bg-white p-4 text-black transition duration-300 ease-in-out hover:bg-brand-dark hover:text-white sm:inline-flex sm:w-auto sm:space-x-3 sm:px-8 sm:py-3 md:left-0 md:top-0 sm:h-auto`
        default:
          return `${defaultStyling} bg-brand-blue border-brand-blue border-2 hover:bg-transparent hover:text-brand-blue`
      }
    },
  },
  methods: {},
})

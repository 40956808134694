/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-arrow--bold': {
    width: 26,
    height: 14,
    viewBox: '0 0 26 14',
    data: '<path pid="0" d="M7 12L2 7m0 0l5-5M2 7h22.5" _stroke="#1F2834" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})

import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=01bf3fcc&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=01bf3fcc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonPrimary: require('/home/ucas-impact-report-prod/ucasimpactreport.com/nuxt/src/components/ButtonPrimary.vue').default,ButtonIcon: require('/home/ucas-impact-report-prod/ucasimpactreport.com/nuxt/src/components/ButtonIcon.vue').default})

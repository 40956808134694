/* tslint:disable */
require('./icon-arrow--bold')
require('./icon-arrow--slider')
require('./icon-arrow')
require('./icon-burger-menu')
require('./icon-chevron--long')
require('./icon-close-button')
require('./icon-download')
require('./icon-email')
require('./icon-facebook')
require('./icon-print')
require('./icon-twitter')

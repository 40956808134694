
export default {
  name: 'AppHeader',
  data() {
    return {
      navItems: [
        {
          name: 'Discover',
          url: 'https://www.ucas.com/discover',
        },
        {
          name: 'Study',
          url: 'https://www.ucas.com/undergraduate/what-where-study',
        },
        {
          name: 'Train & Study',
          url: 'https://www.ucas.com/apprenticeships',
        },
      ],
    }
  },
}


import Vue from 'vue'

export default Vue.extend({
  name: 'ButtonIcon',
  props: {
    title: {
      required: true,
      type: String,
    },
    url: {
      required: false,
      type: String,
      default: '',
    },
    icon: {
      required: true,
      type: String,
    },
    overide: {
      required: false,
      type: String,
      default: 'link',
    },
    theme: {
      required: false,
      type: String,
      default: '',
    },
    colour: {
      required: false,
      type: String,
      default: '',
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      let size =
        'w-10 min-w-10 h-10 max-h-10 min-h-10 sm:w-16 sm:min-w-16 sm:h-16]'
      const defaultStyling = 'rounded-full flex justify-center items-center'

      let isDisabled = ''

      if (this.isDisabled === true) {
        isDisabled = 'cursor-no-drop pointer-events-none opacity-30'
      }

      if (this.theme === 'slider') {
        size = 'w-13 h-13'
      }

      if (this.theme === 'home') {
        size += 'min-h-10 min-w-10 md:min-h-[4rem] md:min-w-[4rem]'
      }

      return `${defaultStyling} ${
        (this as any).colourOveride
      } ${size} ${isDisabled}`
    },
    iconStyleOveride(): string {
      if (this.theme === 'slider') {
        return 'w-4 fill-current'
      }

      if (this.theme === 'share') {
        return 'w-4 h-4 fill-current'
      }

      return ''
    },
    colourOveride(): string {
      if (this.colour === 'blue--solid') {
        return 'bg-brand-blue border-brand-blue border-2 hover:bg-transparent transition-colors text-white'
      }
      if (this.colour === 'blue') {
        return 'bg-white border-white border-2 hover:bg-transparent transition-colors text-brand-blue hover:opacity-50 hover:text-white'
      }
      if (this.colour === 'purple') {
        return 'bg-white border-white border-2 hover:bg-transparent transition-colors text-brand-purple hover:text-white'
      }
      if (this.colour === 'black') {
        return 'bg-transparent border-black border-2 hover:bg-black transition-colors text-black hover:text-white'
      }

      return 'bg-white border border-[#D2D2D2]'
    },
  },
})

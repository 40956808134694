export const AppFooter = () => import('../../src/components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../src/components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppNavigation = () => import('../../src/components/AppNavigation.vue' /* webpackChunkName: "components/app-navigation" */).then(c => wrapFunctional(c.default || c))
export const AppSwiper = () => import('../../src/components/AppSwiper.vue' /* webpackChunkName: "components/app-swiper" */).then(c => wrapFunctional(c.default || c))
export const ButtonIcon = () => import('../../src/components/ButtonIcon.vue' /* webpackChunkName: "components/button-icon" */).then(c => wrapFunctional(c.default || c))
export const ButtonPrimary = () => import('../../src/components/ButtonPrimary.vue' /* webpackChunkName: "components/button-primary" */).then(c => wrapFunctional(c.default || c))
export const CardDeck = () => import('../../src/components/CardDeck.vue' /* webpackChunkName: "components/card-deck" */).then(c => wrapFunctional(c.default || c))
export const CardScroller = () => import('../../src/components/CardScroller.vue' /* webpackChunkName: "components/card-scroller" */).then(c => wrapFunctional(c.default || c))
export const CountUp = () => import('../../src/components/CountUp.vue' /* webpackChunkName: "components/count-up" */).then(c => wrapFunctional(c.default || c))
export const HorizontalScroll = () => import('../../src/components/HorizontalScroll.vue' /* webpackChunkName: "components/horizontal-scroll" */).then(c => wrapFunctional(c.default || c))
export const LineSeparator = () => import('../../src/components/LineSeparator.vue' /* webpackChunkName: "components/line-separator" */).then(c => wrapFunctional(c.default || c))
export const LoadingScreen = () => import('../../src/components/LoadingScreen.vue' /* webpackChunkName: "components/loading-screen" */).then(c => wrapFunctional(c.default || c))
export const ParagraphBanner = () => import('../../src/components/ParagraphBanner.vue' /* webpackChunkName: "components/paragraph-banner" */).then(c => wrapFunctional(c.default || c))
export const ParagraphCallToAction = () => import('../../src/components/ParagraphCallToAction.vue' /* webpackChunkName: "components/paragraph-call-to-action" */).then(c => wrapFunctional(c.default || c))
export const ParagraphCaseStudies = () => import('../../src/components/ParagraphCaseStudies.vue' /* webpackChunkName: "components/paragraph-case-studies" */).then(c => wrapFunctional(c.default || c))
export const ParagraphDivider = () => import('../../src/components/ParagraphDivider.vue' /* webpackChunkName: "components/paragraph-divider" */).then(c => wrapFunctional(c.default || c))
export const ParagraphIntroduction = () => import('../../src/components/ParagraphIntroduction.vue' /* webpackChunkName: "components/paragraph-introduction" */).then(c => wrapFunctional(c.default || c))
export const ParagraphLinks = () => import('../../src/components/ParagraphLinks.vue' /* webpackChunkName: "components/paragraph-links" */).then(c => wrapFunctional(c.default || c))
export const ParagraphMedia = () => import('../../src/components/ParagraphMedia.vue' /* webpackChunkName: "components/paragraph-media" */).then(c => wrapFunctional(c.default || c))
export const ParagraphOverview = () => import('../../src/components/ParagraphOverview.vue' /* webpackChunkName: "components/paragraph-overview" */).then(c => wrapFunctional(c.default || c))
export const ParagraphShowcase = () => import('../../src/components/ParagraphShowcase.vue' /* webpackChunkName: "components/paragraph-showcase" */).then(c => wrapFunctional(c.default || c))
export const ParagraphStats = () => import('../../src/components/ParagraphStats.vue' /* webpackChunkName: "components/paragraph-stats" */).then(c => wrapFunctional(c.default || c))
export const ParagraphTestimonials = () => import('../../src/components/ParagraphTestimonials.vue' /* webpackChunkName: "components/paragraph-testimonials" */).then(c => wrapFunctional(c.default || c))
export const ParagraphTextSection = () => import('../../src/components/ParagraphTextSection.vue' /* webpackChunkName: "components/paragraph-text-section" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../src/components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProseParagraph = () => import('../../src/components/ProseParagraph.vue' /* webpackChunkName: "components/prose-paragraph" */).then(c => wrapFunctional(c.default || c))
export const TransitionExpanded = () => import('../../src/components/TransitionExpanded.vue' /* webpackChunkName: "components/transition-expanded" */).then(c => wrapFunctional(c.default || c))
export const AppSwiperNavigation = () => import('../../src/components/AppSwiper/SwiperNavigation.vue' /* webpackChunkName: "components/app-swiper-navigation" */).then(c => wrapFunctional(c.default || c))
export const AppSwiperPagination = () => import('../../src/components/AppSwiper/SwiperPagination.vue' /* webpackChunkName: "components/app-swiper-pagination" */).then(c => wrapFunctional(c.default || c))
export const AppSwiperSlide = () => import('../../src/components/AppSwiper/SwiperSlide.vue' /* webpackChunkName: "components/app-swiper-slide" */).then(c => wrapFunctional(c.default || c))
export const AppSwiperWrapper = () => import('../../src/components/AppSwiper/SwiperWrapper.vue' /* webpackChunkName: "components/app-swiper-wrapper" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

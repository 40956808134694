/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-facebook': {
    width: 8,
    height: 16,
    viewBox: '0 0 8 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 3.003l-1.45.001c-1.137 0-1.358.528-1.358 1.302v1.71h2.713L7.55 8.691H5.192v6.867H2.365V8.692H0V6.015h2.365V4.042C2.365 1.75 3.796.504 5.887.504 6.888.504 7.749.576 8 .609v2.394z" _fill="#000"/>'
  }
})

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-arrow': {
    width: 15,
    height: 8,
    viewBox: '0 0 15 8',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.693 7.307a.419.419 0 01-.592.003L.124 4.363a.419.419 0 010-.595L3.101.821a.419.419 0 11.589.595L1.436 3.647h12.378a.419.419 0 110 .837H1.436L3.69 6.715a.419.419 0 01.003.592z" _fill="#fff"/>'
  }
})


export default {
  name: 'AppFooter',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    currentPage() {
      return window.location.href
    },
  },
  methods: {
    printPage(e) {
      e.preventDefault()
      window.print()
    },
  },
}

/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'icon-print': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 3a3 3 0 013-3h6a3 3 0 013 3v3h1a3 3 0 013 3v4a3 3 0 01-3 3h-1v1a3 3 0 01-3 3H7a3 3 0 01-3-3v-1H3a3 3 0 01-3-3V9a3 3 0 013-3h1V3zm2 3h8V3a1 1 0 00-1-1H7a1 1 0 00-1 1v3zM3 8a1 1 0 00-1 1v4a1 1 0 001 1h1v-1a3 3 0 013-3h6a3 3 0 013 3v1h1a1 1 0 001-1V9a1 1 0 00-1-1H3zm11 5a1 1 0 00-1-1H7a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1v-4z" _fill="#000"/>'
  }
})

type AppNavigation = {
  open: Boolean
}

export const state = () => ({
  open: false,
})

export const mutations = {
  setNavigation(state: AppNavigation, payload: Boolean) {
    state.open = payload
  },
}

export const actions = {}
